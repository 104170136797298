import React from 'react';
import {FormattedMessage} from 'react-intl';

import musicIconURL from './music/music.png';
import musicInsetIconURL from './music/music-small.svg';

import penIconURL from './pen/pen.png';
import penInsetIconURL from './pen/pen-small.svg';

import videoSensingIconURL from './videoSensing/video-sensing.png';
import videoSensingInsetIconURL from './videoSensing/video-sensing-small.svg';

import text2speechIconURL from './text2speech/text2speech.png';
import text2speechInsetIconURL from './text2speech/text2speech-small.svg';
import bdesignerarduinoImage from './1.png';
import bdesignerarduinoImage1 from './142.png';
import translateIconURL from './translate/translate.png';
import translateInsetIconURL from './translate/translate-small.png';

import makeymakeyIconURL from './makeymakey/makeymakey.png';
import makeymakeyInsetIconURL from './makeymakey/makeymakey-small.svg';

import microbitIconURL from './microbit/microbit.png';
import microbitInsetIconURL from './microbit/microbit-small.svg';
import microbitConnectionIconURL from './microbit/microbit-illustration.svg';
import microbitConnectionSmallIconURL from './microbit/microbit-small.svg';

import ev3IconURL from './ev3/ev3.png';
import ev3InsetIconURL from './ev3/ev3-small.svg';
import ev3ConnectionIconURL from './ev3/ev3-hub-illustration.svg';
import ev3ConnectionSmallIconURL from './ev3/ev3-small.svg';

import wedo2IconURL from './wedo2/wedo.png'; // TODO: Rename file names to match variable/prop names?
import wedo2InsetIconURL from './wedo2/wedo-small.svg';
import wedo2ConnectionIconURL from './wedo2/wedo-illustration.svg';
import wedo2ConnectionSmallIconURL from './wedo2/wedo-small.svg';
import wedo2ConnectionTipIconURL from './wedo2/wedo-button-illustration.svg';

import boostIconURL from './boost/boost.png';
import boostInsetIconURL from './boost/boost-small.svg';
import boostConnectionIconURL from './boost/boost-illustration.svg';
import boostConnectionSmallIconURL from './boost/boost-small.svg';
import boostConnectionTipIconURL from './boost/boost-button-illustration.svg';

import gdxforIconURL from './gdxfor/gdxfor.png';
import gdxforInsetIconURL from './gdxfor/gdxfor-small.svg';
import gdxforConnectionIconURL from './gdxfor/gdxfor-illustration.svg';
import gdxforConnectionSmallIconURL from './gdxfor/gdxfor-small.svg';

import faceapiImage from './46.png';
import qrcode from './qrcode.png';
import qrcode1 from './qrcode-small.svg';
import googleaiIconURL from './27.png';
import googleai2IconURL from './34.png';
import securityImage from './33.png';
import bdesignerk210Image from './16.png';
import bdesignerpseudocodeImage from './22.png';
import posenet2scratchImage from './28.png';
import handposescratchImage from './31.png';
import ml2scratchImage from './29.png';
import googleposescratchImage from './30.png';
import facescratchImage from './41.png';
import mqttscratchImage from './38.png';
import pocketmqttscratchImage from './49.png';
import esp32mqttscratchImage from './52.jpg';
import esp32blescratchImage from './24.jpg';
import esp32blescratchImage1 from './23.jpg';
import pocketblescratchImage from './18.png';
import pocketblescratchImage1 from './19.png';
import hivemqscratchImage from './50.png';
import mqtt1scratchImage from './39.png';
import microbitscratchImage from './127.png';
import microbitscratch2Image from './124.png';
import stringImage from './45.jpg';
import jsonImage from './47.png';
import speechImage from './42.jpg';
import grouqcloudImage3 from './grouqcloud.jpg';
import speechImage1 from './49.jpg';
import makeImage from './54.jpg';
import thingspeakImage from './55.jpg';
import cloudImage from './56.jpg';
import linenotifyImage from './57.jpg';
import speechImage2 from './99.png';
import speechImage3 from './121.jpg';
export default [
    {
        name: (
            <FormattedMessage
                defaultMessage="Music"
                description="Name for the 'Music' extension"
                id="gui.extension.music.name"
            />
        ),
        extensionId: 'music',
        iconURL: musicIconURL,
        insetIconURL: musicInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Play instruments and drums."
                description="Description for the 'Music' extension"
                id="gui.extension.music.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pen"
                description="Name for the 'Pen' extension"
                id="gui.extension.pen.name"
            />
        ),
        extensionId: 'pen',
        iconURL: penIconURL,
        insetIconURL: penInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Draw with your sprites."
                description="Description for the 'Pen' extension"
                id="gui.extension.pen.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Video Sensing"
                description="Name for the 'Video Sensing' extension"
                id="gui.extension.videosensing.name"
            />
        ),
        extensionId: 'videoSensing',
        iconURL: videoSensingIconURL,
        insetIconURL: videoSensingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense motion with the camera."
                description="Description for the 'Video Sensing' extension"
                id="gui.extension.videosensing.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Text to Speech"
                description="Name for the Text to Speech extension"
                id="gui.extension.text2speech.name"
            />
        ),
        extensionId: 'text2speech',
        collaborator: 'Amazon Web Services',
        iconURL: text2speechIconURL,
        insetIconURL: text2speechInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make your projects talk."
                description="Description for the Text to speech extension"
                id="gui.extension.text2speech.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Translate"
                description="Name for the Translate extension"
                id="gui.extension.translate.name"
            />
        ),
        extensionId: 'translate',
        collaborator: 'Google',
        iconURL: translateIconURL,
        insetIconURL: translateInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Translate text into many languages."
                description="Description for the Translate extension"
                id="gui.extension.translate.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: 'bDesigner(Arduino)',
        extensionId: 'bdesignerarduino',
        iconURL: bdesignerarduinoImage,
	collaborator: 'Jia-Lun Tsai',
        description: '這個積木組提供Arduino Uno的藍芽連線，需要HM-10',
        featured: true
    },
    {
        name: 'bDesigner(Arduinoself)',
        extensionId: 'arduinoself',
        iconURL: bdesignerarduinoImage1,
	collaborator: 'Jia-Lun Tsai',
        description: '這個積木組提供Arduino Uno的自訂韌體的藍芽連線，需要HM-10',
        featured: true
    },
    {
        name: 'bDesigner(Microbit bluetooth)',
        extensionId: 'microbitblue',
        iconURL: microbitscratch2Image,
        insetIconURL: microbitscratch2Image,
        description: "透過BLE藍芽遙控用燒錄好的Microbit v2",
        featured: true
    },
    {
        name: 'bDesigner(Microbit bluetooth self)',
        extensionId: 'microbitblue1',
        iconURL: microbitscratchImage,
        insetIconURL: microbitscratchImage,
        description: "透過BLE藍芽遙控用MakeCode自己寫的Microbit v2",
        featured: true
    },
    {
        name: 'bDesigner(ESP32Mqtt)',
        extensionId: 'esp32mqtt',
        iconURL: esp32mqttscratchImage,
        insetIconURL: esp32mqttscratchImage,
        description: "MQTT控制ESP32",
        featured: true
    },
    {
        name: 'bDesigner(PocketMqtt)',
        extensionId: 'pocketmqtt',
        iconURL: pocketmqttscratchImage,
        insetIconURL: pocketmqttscratchImage,
        description: "MQTT控制Pocket",
        featured: true
    },
    {
        name: 'bDesigner(ESP32BLE)',
        extensionId: 'esp32ble',
        iconURL: esp32blescratchImage,
        insetIconURL: esp32blescratchImage,
        description: "BLE控制ESP32",
        featured: true
    },

    {
        name: 'bDesigner(PocketBLE)',
        extensionId: 'pocketble',
        iconURL: pocketblescratchImage,
        insetIconURL: pocketblescratchImage,
        description: "BLE控制Pocket",
        featured: true
    },
    {
        name: 'bDesigner(ESP32BLEself)',
        extensionId: 'esp32bleself',
        iconURL: esp32blescratchImage1,
        insetIconURL: esp32blescratchImage1,
        description: "BLE控制自訂的ESP32和Pocketcard等ESP32裝置",
        featured: true
    },
    {
        name: 'bDesigner(HiveMqtt)',
        extensionId: 'hivemq',
        iconURL: hivemqscratchImage,
        insetIconURL: hivemqscratchImage,
        description: "預設使用Hivemq的MQTT，可以更換其他的Broker，速度較快",
        featured: true
    },
    {
        name: 'bDesigner(Mqtt)',
        extensionId: 'mqtt',
        iconURL: mqttscratchImage,
        insetIconURL: mqttscratchImage,
        description: "Mqtt",
        featured: true
    },
    {
        name: 'bDesigner(Mqtt進階)',
        extensionId: 'mqtt1',
        iconURL: mqtt1scratchImage,
        insetIconURL: mqtt1scratchImage,
        description: "Mqtt進階控制",
        featured: true
    },
    {
        name: 'bDesigner(security)',
        extensionId: 'security',
	collaborator: 'Jia-Lun Tsai',
        iconURL: securityImage,
        description: '這個積木組提供加密函式庫',
        featured: true
    },
    {
        name: 'bDesigner(Posenet)',
        extensionId: 'posenet2scratch',
        iconURL: posenet2scratchImage,
        insetIconURL: posenet2scratchImage,
        description: '人體姿態辨識',
        featured: true
    },
    {
        name: 'bDesigner(googleai)',
        extensionId: 'googleai',
        iconURL: googleaiIconURL,
        insetIconURL: googleaiIconURL,
        description: "Google teachable machine的影像辨識",
        featured: true
    },
    {
        name: 'bDesigner(googlepose)',
        extensionId: 'googlepose',
        iconURL: googleposescratchImage,
        insetIconURL: googleposescratchImage,
        description: "Google teachable machine的姿態影像辨識",
        featured: true
    },
    {
        name: 'bDesigner(Machine Learning)',
        extensionId: 'ML',
        iconURL: ml2scratchImage,
        insetIconURL: ml2scratchImage,
        description: "機器學習的影像辨識",
        featured: true
    },
    {
        name: 'bDesigner(HandPose)',
        extensionId: 'handpose',
        iconURL: handposescratchImage,
        insetIconURL: handposescratchImage,
        description: "手部影像辨識",
        featured: true
    },
    {
        name: 'bDesigner(臉部)',
        extensionId: 'facemesh2scratch',
        iconURL: facescratchImage,
        insetIconURL: facescratchImage,
        description: "臉部位置偵測",
        featured: true
    },
    {
        name: 'bDesigner(google聲音)',
        extensionId: 'googleai2',
        iconURL: googleai2IconURL,
        insetIconURL: googleai2IconURL,
        description: "Google teachable machine的聲音辨識",
        featured: true
    },
    {
        name: 'bDesigner(Face API)',
        extensionId: 'faceapi',
	collaborator: 'Jia-Lun Tsai',
        iconURL: faceapiImage,
        description: '這個積木組專門提供Face API人臉偵測與辨識',
        featured: true
    },
    {
        name: 'bDesigner(語音辨識)',
        extensionId: 'speech2scratch2',
        iconURL: speechImage3,
        insetIconURL: speechImage3,
        description: "提供語音辨識功能",
        featured: true
    },
    {
        name: 'bDesigner(groqcloud)',
        extensionId: 'groqcloud2scratch',
        iconURL: grouqcloudImage3,
        insetIconURL: grouqcloudImage3,
        description: "GroqCloud提供免費的Opengpt",
        featured: true
    },
    {
        name: 'QR Code Scan',
        extensionId: 'qrcode',
        iconURL: qrcode,
        insetIconURL: qrcode1,
	collaborator: 'sugiura-lab',
        description: '這個積木組提供QR Code掃描',
        featured: true
    },
    {
        name: 'bDesigner(Scratch補充積木)',
        extensionId: 'string2scratch',
        iconURL: stringImage,
        insetIconURL: stringImage,
        description: "替scratch新增了部分積木，例如字串功能",
        featured: true
    },
    {
        name: 'bDesigner(JSON下載與處理)',
        extensionId: 'json',
        iconURL: jsonImage,
        description: "JSON下載與處理",
        featured: true
    },
    {
        name: 'bDesigner(google sheet)',
        extensionId: 'speech3scratch',
        iconURL: speechImage2,
        insetIconURL: speechImage2,
        description: "提供用google表單匯入google試算表的功能",
        featured: true
    },
    {
        name: 'bDesigner(OpenWeatherMap)',
        extensionId: 'speech2scratch',
        iconURL: speechImage,
        insetIconURL: speechImage,
        description: "OpenWeatcherMap提供了天氣氣象資訊",
        featured: true
    },
    {
        name: 'bDesigner(Make)',
        extensionId: 'make',
        iconURL: makeImage,
        insetIconURL: makeImage,
        description: "Make服務，用來替代IFTTT",
        featured: true
    },
    {
        name: 'bDesigner(thingspeak)',
        extensionId: 'thingspeak',
        iconURL: thingspeakImage,
        insetIconURL: thingspeakImage,
        description: "thingspeak服務",
        featured: true
    },
    {
        name: 'bDesigner(cloud)',
        extensionId: 'cloud',
        iconURL: cloudImage,
        insetIconURL: cloudImage,
        description: "Scratch雲端變數服務",
        featured: true
    },
    {
        name: 'LEGO MINDSTORMS EV3',
        extensionId: 'ev3',
        collaborator: 'LEGO',
        iconURL: ev3IconURL,
        insetIconURL: ev3InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build interactive robots and more."
                description="Description for the 'LEGO MINDSTORMS EV3' extension"
                id="gui.extension.ev3.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: ev3ConnectionIconURL,
        connectionSmallIconURL: ev3ConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting. Make sure the pin on your EV3 is set to 1234."
                description="Message to help people connect to their EV3. Must note the PIN should be 1234."
                id="gui.extension.ev3.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/ev3'
    },
    {
        name: 'LEGO BOOST',
        extensionId: 'boost',
        collaborator: 'LEGO',
        iconURL: boostIconURL,
        insetIconURL: boostInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Bring robotic creations to life."
                description="Description for the 'LEGO BOOST' extension"
                id="gui.extension.boost.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: boostConnectionIconURL,
        connectionSmallIconURL: boostConnectionSmallIconURL,
        connectionTipIconURL: boostConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their BOOST."
                id="gui.extension.boost.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/boost'
    },
    {
        name: 'LEGO Education WeDo 2.0',
        extensionId: 'wedo2',
        collaborator: 'LEGO',
        iconURL: wedo2IconURL,
        insetIconURL: wedo2InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build with motors and sensors."
                description="Description for the 'LEGO WeDo 2.0' extension"
                id="gui.extension.wedo2.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: wedo2ConnectionIconURL,
        connectionSmallIconURL: wedo2ConnectionSmallIconURL,
        connectionTipIconURL: wedo2ConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their WeDo."
                id="gui.extension.wedo2.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/wedo'
    },
    {
        name: 'Go Direct Force & Acceleration',
        extensionId: 'gdxfor',
        collaborator: 'Vernier',
        iconURL: gdxforIconURL,
        insetIconURL: gdxforInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense push, pull, motion, and spin."
                description="Description for the Vernier Go Direct Force and Acceleration sensor extension"
                id="gui.extension.gdxfor.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: gdxforConnectionIconURL,
        connectionSmallIconURL: gdxforConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their force and acceleration sensor."
                id="gui.extension.gdxfor.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/vernier'
    }
];
